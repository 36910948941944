<template>
  <div>
    <h2>Selamat datang di admin dashboard</h2>
  </div>
</template>

<script>

export default {
  components: {

  },
}
</script>

<style>
</style>
